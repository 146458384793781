import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import SimpleLineIcons from 'react-simple-line-icons';
import { PulseLoader } from 'react-spinners';
import { Alert, Button, Col, Row } from 'reactstrap';

import { isValidEmail } from '../../helpers/regex';
import useTranslate from '../../hooks/useTranslate';
import validateCard from '../../services/apis/validateCard';
import Input from '../form/Input';

const cardValidationErrors = {
  'visits.err-card-blocked': 'default.CartValidationErrors.CardBlocked',
  'visits.err-card-nexist': 'default.CartValidationErrors.CardDoesntExist',
  'visits.err-visit-already': 'default.CartValidationErrors.CardVisitAlready',
  'visits.err-card-nactive': 'default.CartValidationErrors.CardInactive',
  'visits.err-card-year': 'default.CartValidationErrors.CardInvalidYear',
  'visits.err-play-not-valid-date': 'default.CartValidationErrors.ClubNotAvailableAtDate',
};

const defaultValues = {
  emailGolfId: '',
  cardNr: '',
  firstName: '',
  lastName: '',
};

const validateEmail = email =>
  !isValidEmail(email) ? 'default.CartValidationErrors.EmptyPlayerEmailId' : null;

const isEmailAlreadyAdded = (players, email) =>
  players.some(player => player.email === email)
    ? 'default.CartValidationErrors.EmailExists'
    : null;

const validateCardNr = email =>
  !/^\d{8}$/.test(email) ? 'default.CartValidationErrors.WrongCardNumber' : null;

const isCardNrAlreadyAdded = (players, email) =>
  players.some(player => player.ghCardNr === email)
    ? 'default.CartValidationErrors.CardAdded'
    : null;

const validateCardNumber = async (ghCardNr, golfCourseId, startTime) => {
  try {
    const body = {
      ghCardNr,
      golfCourseId,
      startTime,
      email: '',
    };
    const error = await validateCard(body);
    if (error?.errorCode || error?.internalMessage) {
      return (
        cardValidationErrors[error.errorCode] ||
        cardValidationErrors[error.internalMessage] ||
        'default.CartValidationErrors.InternalError'
      );
    }
  } catch (error) {
    return 'default.CartValidationErrors.InternalError';
  }

  return null;
};

export function Players({
  players,
  onPlayersUpdated,
  userFriends,
  onFriendAddClick,
  isGolfhaftetRequest,
  isThirdParty,
  item,
}) {
  const seatsInFlight = item?.amount;
  const playersInFlight = players.filter(p => p.selected).length;
  const flightFull = playersInFlight === item?.amount;

  return (
    <div>
      <PlayersInFlight
        item={item}
        players={players}
        playersInFlight={playersInFlight}
        seatsInFlight={seatsInFlight}
        flightFull={flightFull}
        onPlayersUpdated={onPlayersUpdated}
        isGolfhaftetRequest={isGolfhaftetRequest}
      />

      {!flightFull && userFriends?.length > 0 && (
        <Friends
          item={item}
          players={players}
          friends={userFriends}
          flightFull={flightFull}
          onFriendAddClick={onFriendAddClick}
          isGolfhaftetRequest={isGolfhaftetRequest}
        />
      )}

      {!flightFull && (
        <ManualFriends
          players={players}
          item={item}
          isGolfhaftetRequest={isGolfhaftetRequest}
          isThirdParty={isThirdParty}
          onPlayersUpdated={onPlayersUpdated}
        />
      )}
    </div>
  );
}

const PlayersInFlight = ({
  item,
  players,
  playersInFlight,
  seatsInFlight,
  flightFull,
  onPlayersUpdated,
  isGolfhaftetRequest,
}) => {
  const { translate } = useTranslate();

  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);

  const onPlayerClick = async player => {
    const playerId = player.id;

    // Only in Golfamore bookings is it possible to remove your self from the flight
    if (!isGolfhaftetRequest && player.isLoggedInProfile) {
      return;
    }

    setError(null);

    if (isGolfhaftetRequest && !player.selected) {
      let error = validateCardNr(player.ghCardNr);
      if (error) {
        setError({ playerId, message: translate(error) });
        return;
      }

      setIsValidating(playerId);
      error = await validateCardNumber(player.ghCardNr, item.golfCourseId, item.startTime);
      setIsValidating(false);
      if (error) {
        setError({ playerId, message: translate(error) });
        return;
      }
    }

    onPlayerAddClick(playerId);
  };

  const onPlayerAddClick = playerId => {
    onPlayersUpdated(
      item.id,
      players.map(p => (p.id === playerId ? { ...p, selected: !p.selected } : { ...p }))
    );
  };

  return (
    <>
      <p className="mt-3 mb-1">
        <Translate id="default.BookingPlayers" /> ({playersInFlight}/{seatsInFlight})
      </p>
      {players?.map(p => (
        <Button
          key={p.id}
          className={`d-flex py-2 px-3 border mb-1 player ${p.selected ? 'selected' : ''}`}
          block
          color="link"
          disabled={flightFull && !p.selected}
          onClick={() => onPlayerClick(p)}
        >
          <SimpleLineIcons size="medium" name={p.selected ? 'check' : 'plus'} />{' '}
          {p.name || p.email || p.golfId} {isGolfhaftetRequest && p.ghCardNr && ` - ${p.ghCardNr}`}
          {isValidating === p.id && <PulseLoader size={10} />}
          {error && error.playerId === p.id && (
            <Alert color="danger" className="p-1">
              {error.message}
            </Alert>
          )}
        </Button>
      ))}
    </>
  );
};

const Friends = ({ item, players, friends, flightFull, onFriendAddClick, isGolfhaftetRequest }) => {
  const { translate } = useTranslate();

  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);

  const onFriendClick = async friend => {
    const friendId = friend.id;

    if (isGolfhaftetRequest) {
      let error = validateCardNr(friend.ghCardNr);
      if (error) {
        setError({ friendId, message: translate(error) });
        return;
      }

      error = isCardNrAlreadyAdded(players, friend.ghCardNr);
      if (error) {
        setError({ friendId, message: translate(error) });
        return;
      }

      setIsValidating(friend.id);
      error = await validateCardNumber(friend.ghCardNr, item.golfCourseId, item.startTime);
      setIsValidating(false);
      if (error) {
        console.log('validateCardNumber', error);
        setError({ friendId, message: translate(error) });
        return;
      }
    }

    onFriendAddClick(item.id, friend.id);
  };

  return (
    <>
      <p className="mt-3 mb-1">
        <Translate id="default.AddFriend" />
      </p>

      {friends?.map(u => (
        <Button
          key={u.id}
          className={`d-flex py-2 px-3 border mb-1 player ${u.selected ? 'selected' : ''}`}
          block
          color="link"
          disabled={flightFull && !u.selected}
          onClick={() => onFriendClick(u)}
        >
          <SimpleLineIcons size="medium" name={u.selected ? 'check' : 'plus'} />{' '}
          {(u.firstName
            ? `${u.firstName} ${u.lastName}${u.golfId ? ', ' + u.golfId : ''}`
            : null) ||
            u.mail ||
            u.golfId}{' '}
          {isGolfhaftetRequest && u.ghCardNr && ` - ${u.ghCardNr}`}
          {isValidating === u.id && <PulseLoader size={10} />}
          {error && error.friendId === u.id && (
            <Alert color="danger" className="p-1">
              {error.message}
            </Alert>
          )}
        </Button>
      ))}
    </>
  );
};

const ManualFriends = ({ players, item, isGolfhaftetRequest, isThirdParty, onPlayersUpdated }) => {
  const { translate } = useTranslate();

  const [isValidating, setIsValidating] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm(defaultValues);

  const validateInputEmail = value => {
    let error = validateEmail(value);
    if (error) {
      return String(translate(error));
    }

    error = isEmailAlreadyAdded(players, value);
    if (error) {
      return String(translate(error));
    }

    return null;
  };

  const validateInputCardNr = value => {
    let error = validateCardNr(value);
    if (error) {
      return String(translate(error));
    }

    error = isCardNrAlreadyAdded(players, value);
    if (error) {
      return String(translate(error));
    }

    return null;
  };

  const validateSubmit = async data => {
    setIsValidating(true);
    const error = await validateCardNumber(data.cardNr, item.golfCourseId, item.startTime);
    setIsValidating(false);
    if (error) {
      setError('cardNr', { type: 'server', message: translate(error) });
      return error;
    }

    return null;
  };

  const onSubmit = async data => {
    if (isGolfhaftetRequest) {
      const error = await validateSubmit(data);
      if (error) {
        return;
      }
    }

    reset(defaultValues);

    let userData = {};
    if (isThirdParty) {
      userData = {
        id: `${data.firstName}${data.lastName}`,
        name: `${data.firstName} ${data.lastName}`,
      };
    } else {
      userData = {
        id: data.emailGolfId,
        email: data.emailGolfId,
        ghCardNr: data.cardNr,
      };
    }

    onPlayersUpdated(item.id, [
      ...players,
      {
        ...userData,
        selected: true,
      },
    ]);
  };

  return (
    <>
      <p className="mt-3 mb-1">
        {isGolfhaftetRequest ? (
          <Translate id="default.CartAddPlayer.NewPlayerGH" />
        ) : isThirdParty ? (
          <Translate id="default.CartAddPlayer.NewPlayer" />
        ) : (
          <Translate id="PlayersSelectionComponentAddByEmailOrGolfId" />
        )}
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {isThirdParty ? (
            <>
              <Col xs="12" sm="6">
                <Input
                  type="text"
                  name="firstName"
                  placeholder={translate('default.FirstName')}
                  disabled={isValidating}
                  register={register}
                  rules={{ required: true }}
                  error={
                    errors.firstName &&
                    translate('default.CartValidationErrors.EmptyPlayerFirstName')
                  }
                />
              </Col>
              <Col xs="12" sm="6">
                <Input
                  type="text"
                  name="lastName"
                  placeholder={translate('default.LastName')}
                  disabled={isValidating}
                  register={register}
                  rules={{ required: true }}
                  error={
                    errors.lastName && translate('default.CartValidationErrors.EmptyPlayerLastName')
                  }
                />
              </Col>
            </>
          ) : (
            <Col xs="12" sm="6">
              <Input
                type="text"
                name="emailGolfId"
                placeholder={translate('default.EmailOrGolfId')}
                disabled={isValidating}
                register={register}
                rules={{ validate: validateInputEmail }}
                error={errors.emailGolfId?.message}
              />
            </Col>
          )}
          {isGolfhaftetRequest && (
            <Col xs="12" sm="6">
              <Input
                type="text"
                name="cardNr"
                placeholder={translate('default.CartAddPlayer.PlaceholderCardNumber')}
                disabled={isValidating}
                register={register}
                rules={{ validate: validateInputCardNr }}
                error={errors.cardNr?.message}
              />
            </Col>
          )}
          <Col xs="12" className="mt-2">
            <Button color="success" disabled={isValidating}>
              {isValidating && <PulseLoader size={10} />}
              <Translate id="default.Add" />
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};
