// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_]).{6,}$/;

export const isValidEmail = (value: string) => emailRegex.test(value);

export const golfIdRegex = /^\d{6}-\d{3}$|^\d{9}$/;

export const isValidGolfId = (value: string) => golfIdRegex.test(value);

export const isValidEmailOrGolfId = (value: string) =>
  (value.includes('@') && isValidEmail(value)) || isValidGolfId(value);
